import {Chart} from 'chart.js/auto';
import React, {useEffect, useReducer, useRef} from 'react';
import BaseQueryBox from './BaseQueryBox';
import {dataStateReducer, useMessageHistory} from './utils';

const ChartVis = ({data, columns}) => {
  const chartRef = useRef(null);
  const [{errored, loading, code, lastQuery}, dispatchVisualDataState] = useReducer(dataStateReducer, {
    errored: false,
    loading: false,
    code: '',
    lastQuery: '',
  });

  const history = useMessageHistory();

  const onVisualise = (value) => {
    if (value) {
      history.current.lastQuery = value;
      dispatchVisualDataState({type: 'loading', payload: value});
      fetch('https://pmkqpb5nq8.execute-api.ap-southeast-2.amazonaws.com/default/webfoxWebsiteChat2Vis/visual', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [...history.current?.messages, {role: 'user', content: value}],
          columns: columns,
        }),
      }).then(response => response.json())
        .then((data) => {
          history.current.lastResponseProcessed = false;
          dispatchVisualDataState({type: 'success', payload: data?.content})
        })
        .catch(() => dispatchVisualDataState({type: 'error'}));
    }
  };

  useEffect(() => {
    if (chartRef?.current && code) {
      try {
        const generateConfig = eval(`(${code})`);

        if (typeof generateConfig !== 'function') {
          throw new Error('Invalid config generator');
        }

        let config = generateConfig(data);
        if (typeof config === 'function') {
          config = config(data);
        }

        if (!history.current.lastResponseProcessed) {
          history.current.lastResponseProcessed = true;
          history.current.push({role: 'user', content: history.current?.lastQuery}, {role: 'assistant', content: code});
        }

        const chart = new Chart(chartRef.current, {...config, maintainAspectRatio: false, responsive: true});

        return () => chart.destroy();
      } catch (error) {
        dispatchVisualDataState({type: 'error'});
      }
    }
  }, [chartRef, data, code]);

  return (
    <BaseQueryBox
      loading={loading}
      errored={errored}
      onQuery={onVisualise}
      lastQuery={lastQuery}
      onClear={() => {
        dispatchVisualDataState({type: 'clear'});
        history.current.clear();
        if (chartRef?.current) {
          const chart = Chart.getChart(chartRef.current);
          if (chart) {
            chart.destroy();
          }
        }
      }}
      loadingMessage="Generating visualisation"
      erroredMessage="The visualisation could not be generated. Please try again later."
      placeholderMessage="Enter a query to graph the sample data above…"
      inputPlaceholder="What visualization do you envision?"
      showPlaceholder={!code}
    >
      <div key="chart" className="ai-response-chart">
        <canvas key="chart" ref={chartRef} style={{
          opacity: loading || errored ? 0 : 1,
        }}/>
      </div>
    </BaseQueryBox>
  );
};

export default ChartVis;