import {mdiClose, mdiRefresh} from '@mdi/js';
import Icon from '@mdi/react';
import {AnimatePresence, motion} from 'framer-motion';
import React from 'react';

const Error = ({onRetry, ...props}) => (
  <motion.div
    className="ai-response-error-container"
    initial={{
      opacity: 0,
      y: 20,
    }}
    animate={{opacity: 1, y: 0}}
    exit={{
      opacity: 0,
      y: 20,
    }}
    {...props}
  >
    <h3>Something went wrong</h3>
    <p>
      This is a prototype, sometimes things don't work as expected
      <br/>
      Hit the button below to try again.
    </p>
    <button type="button" onClick={onRetry}>
      <Icon path={mdiRefresh} size={1}/>
      <span>Ask again</span>
    </button>
  </motion.div>
);

const Loading = ({message, ...props}) => (
  <motion.div
    className="ai-response-loader-container"
    initial={{
      opacity: 0,
      y: 20,
    }}
    animate={{opacity: 1, y: 0}}
    exit={{
      opacity: 0,
      y: 20,
    }}
    {...props}
  >
    <div className="ai-response-loader"/>
    <span>{message || 'Loading'}</span>
  </motion.div>
);

const ContainerPlaceholder = ({children, ...props}) => (
  <motion.div
    className="ai-response-placeholder-container"
    initial={{
      opacity: 0,
      y: 20,
    }}
    animate={{opacity: 1, y: 0}}
    exit={{
      opacity: 0,
      y: 20,
    }}
    {...props}
  >
    <span>{children}</span>
  </motion.div>
);

const BaseQueryBox = ({
  onQuery,
  loading,
  errored,
  lastQuery,
  onClear,
  loadingMessage = 'Generating',
  erroredMessage = 'Failed to generate, please try again later',
  placeholderMessage = 'Enter a query to generate from our example dataset below...',
  inputPlaceholder = 'Enter query',
  children,
  showPlaceholder,
}) => {
  const resolvedChildren = (typeof children === 'function' ? children() : children);

  return (
    <AnimatePresence mode="popLayout">
      <input key="input-textbox" type="text" className="ai-input" placeholder={inputPlaceholder}
             onKeyDown={(e) => {
               if (loading) return;
               if (e.key === 'Enter') {
                 onQuery(e?.target?.value);
                 e.target.value = '';
               }
             }}/>
      {!!lastQuery &&
        <motion.div
          key={`last-query-${lastQuery}`}
          className="ai-last-query"
          initial={{
            opacity: 0,
            y: -20,
          }}
          animate={{opacity: 1, y: 0}}
          exit={{
            opacity: 0,
            y: 20,
          }}
        >
          <i>"{lastQuery}"</i>
        </motion.div>
      }
      <div key="response-container" className="ai-response-container">
        <AnimatePresence>
          {loading ?
            <Loading key="ai-response-loader" message={loadingMessage}/>
            :
            errored ?
              <Error key="ai-response-error" message={erroredMessage}/>
              :
              <>
                {resolvedChildren}
                {(!resolvedChildren || !!showPlaceholder) &&
                  <ContainerPlaceholder key="ai-response-placeholder">
                    {placeholderMessage}
                  </ContainerPlaceholder>
                }
              </>
          }
        </AnimatePresence>
      </div>
      <div key="clear-conversation" className="ai-clear">
        <button type="button" onClick={onClear} disabled={loading}>
          <Icon path={mdiClose} size={1}/>
          <span>Clear Conversation</span>
        </button>
      </div>
    </AnimatePresence>
  );
};

export default BaseQueryBox;
