export default {
  'allAlbumsCsv': {
    title: 'Top 10 Albums by Year',
    columns: [
      {
        key: 'Year',
        title: 'Year',
        description: 'the year of album release',
        transformer: (value) => Number(value),
      },
      {
        key: 'Ranking',
        title: 'Ranking',
        description: 'the highest chart position achieved by the album',
        transformer: (value) => Number(value),
      },
      {
        key: 'Artist',
        title: 'Artist',
        description: 'the artist who released the album',
        transformer: (value) => value,
      },
      {
        key: 'Album',
        title: 'Album',
        description: 'the name of the album',
        transformer: (value) => value,
      },
      {
        key: 'Worldwide_Sales',
        title: 'Worldwide Sales',
        description: 'the number of copies sold worldwide',
        transformer: (value) => Number(value),
      },
      {
        key: 'Tracks',
        title: 'Tracks',
        description: 'the number of tracks on the album',
        transformer: (value) => Number(value),
      },
      {
        key: 'Album_Length',
        title: 'Album Length',
        description: 'the length of the album in minutes and seconds (formatted mm:ss)',
        transformer: (value) => value,
      },
      {
        key: 'Genre',
        title: 'Genre',
        description: 'the genre of the album',
        transformer: (value) => value,
      },
    ],
  },
  'allSalesCsv': {
    title: 'Sales by Product',
    columns: [
      {
        key: 'ORDERNUMBER',
        title: 'Order #',
        description: 'the order number',
        transformer: (value) => value,
      },
      {
        key: 'QUANTITYORDERED',
        title: 'Qty. Ordered',
        description: 'the quantity ordered',
        transformer: (value) => Number(value),
      },
      {
        key: 'PRICEEACH',
        title: 'Price Each',
        description: 'the price each',
        transformer: (value) => Number(value),
      },
      {
        key: 'ORDERLINENUMBER',
        title: 'Order Line #',
        description: 'the order line number',
        transformer: (value) => Number(value),
      },
      {
        key: 'SALES',
        title: 'Sales',
        description: 'the total sales',
        transformer: (value) => Number(value),
      },
      {
        key: 'ORDERDATE',
        title: 'Order Date',
        description: 'the order date',
        transformer: (value) => value,
      },
      {
        key: 'STATUS',
        title: 'Status',
        description: 'the order status',
        transformer: (value) => value,
      },
      {
        key: 'QTR_ID',
        title: 'Qtr. ID',
        description: 'the quarter ID',
        transformer: (value) => Number(value),
      },
      {
        key: 'MONTH_ID',
        title: 'Month ID',
        description: 'the month ID',
        transformer: (value) => Number(value),
      },
      {
        key: 'YEAR_ID',
        title: 'Year ID',
        description: 'the year ID',
        transformer: (value) => Number(value),
      },
      {
        key: 'PRODUCTLINE',
        title: 'Product Line',
        description: 'the category of the product',
        transformer: (value) => value,
      },
      {
        key: 'MSRP',
        title: 'MSRP',
        description: 'the manufacturer\'s suggested retail price',
        transformer: (value) => Number(value),
      },
      {
        key: 'PRODUCTCODE',
        title: 'Product Code',
        description: 'the product code',
        transformer: (value) => value,
      },
      {
        key: 'CUSTOMERNAME',
        title: 'Customer Name',
        description: 'the customer name',
        transformer: (value) => value,
      },
      {
        key: 'PHONE',
        title: 'Phone',
        description: 'the customer phone number',
        transformer: (value) => value,
      },
      {
        key: 'ADDRESSLINE1',
        title: 'Address Line 1',
        description: 'the customer address line 1',
        transformer: (value) => value,
      },
      {
        key: 'ADDRESSLINE2',
        title: 'Address Line 2',
        description: 'the customer address line 2',
        transformer: (value) => value,
      },
      {
        key: 'CITY',
        title: 'City',
        description: 'the customer city',
        transformer: (value) => value,
      },
      {
        key: 'STATE',
        title: 'State',
        description: 'the customer state',
        transformer: (value) => value,
      },
      {
        key: 'POSTALCODE',
        title: 'Postal Code',
        description: 'the customer postal code',
        transformer: (value) => value,
      },
      {
        key: 'COUNTRY',
        title: 'Country',
        description: 'the customer country',
        transformer: (value) => value,
      },
      {
        key: 'TERRITORY',
        title: 'Territory',
        description: 'the customer territory',
        transformer: (value) => value,
      },
      {
        key: 'CONTACTLASTNAME',
        title: 'Contact Last Name',
        description: 'the customer contact last name',
        transformer: (value) => value,
      },
      {
        key: 'CONTACTFIRSTNAME',
        title: 'Contact First Name',
        description: 'the customer contact first name',
        transformer: (value) => value,
      },
      {
        key: 'DEALSIZE',
        title: 'Deal Size',
        description: 'the deal size (Small, Medium, or Large)',
        transformer: (value) => value,
      },
    ],
  },
};