import {motion, useTransform} from 'framer-motion';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React, {useMemo, useState} from 'react';
import ChartVis from '../../components/AiQuery/ChartVis';
import Insight from '../../components/AiQuery/Insight';
import Layout from '../../components/Layout';
import Seo from '../../components/seo';
import TechLogos from '../../components/TechLogos';
import {useScrollAnimation} from '../../components/useScrollAnimation';
import DataSetSchemas from '../../data/datasets';
import LogoSvg from '../../svgs/logo.svg';

const parseData = (data, columns) => {
  return data?.edges?.map(item => {
    return columns?.reduce((acc, curr) => {
      const val = item?.node?.[curr?.key];
      if (val) {
        acc[curr?.key] = typeof curr?.transformer === 'function' ? curr?.transformer(val) : val;
      }
      return acc;
    }, {});
  });
};

const getColumnDescriptions = (columns) => {
  return columns?.reduce((acc, curr) => {
    acc[curr?.key] = curr?.description;
    return acc;
  }, {});
};


const Graph = ({formattedData, columnDescriptions}) => {
  return (
    <div>
      <div className="ai-header">
        <h6>Pose a question or a follow-up to generate a graph based on the example data above.</h6>
      </div>
      <ChartVis data={formattedData} columns={columnDescriptions}/>
    </div>
  );
};

const Insights = ({formattedData, columnDescriptions}) => {
  return (
    <div>
      <div className="ai-header">
        <h6>Ask a question or a follow-up about the example data above.</h6>
      </div>
      <Insight data={formattedData} columns={columnDescriptions}/>
    </div>
  );
};

export default function Visualisation({data}) {
  const [selectedDataSetKey, setSelectedDataSetKey] = useState('allSalesCsv'); //['allAlbumsCsv', 'allSalesCsv']
  const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

  const {formattedData, columnDescriptions} = useMemo(() => {
    return {
      formattedData: parseData(data?.[selectedDataSetKey], DataSetSchemas?.[selectedDataSetKey]?.columns),
      columnDescriptions: getColumnDescriptions(DataSetSchemas?.[selectedDataSetKey]?.columns),
    };
  }, [data, selectedDataSetKey]);

  const componentOptions = [
    {label: 'View as graph', component: Graph},
    {label: 'Ask a question', component: Insights},
  ];

  const [queryComponent, setQueryComponent] = useState(componentOptions[0]);


  const [bannerRef, bannerScroll] = useScrollAnimation();
  const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);

  return (
    <Layout>
      {/* TODO - configure SEO component */}
      <Seo
        title="AI powered data visualisation showcase | Webfox - AI Visualisation"
        description="We help progressive Kiwi organisations innovate, realise untapped commercial potential and set new standards for digital engagement to give their business an edge."
      />


      <div ref={bannerRef}>
        <section className="layer intro intro-default dark">
          <motion.div animate={{scale: bannerScale.get()}} id="background-video">
            <StaticImage
              src="../../images/case_study.jpg"
              alt="AI Data Visualisation"
              style={{minWidth: '100%', minHeight: '100%', maxWidth: '100%'}}
            />
          </motion.div>


          <div style={{padding: '5em'}}/>
        </section>
        <div className="ai-copy">
          <h1>Private Data Analysis</h1>
          <p>This experiment uses LLMs to extract data from a personal dataset without sharing the actual data with the LLM.</p>
        </div>
      </div>

      <section className="layer">
        <div className="inner">
          <div className="dataset-header">
            <h4>1. Choose dataset</h4>
            <div className="ai-select">
              <select id="datasetSelect" value={selectedDataSetKey} onChange={(e) => setSelectedDataSetKey(e.target.value)}>
                {Object.keys(DataSetSchemas).map((key, index) => (
                  <option key={index} value={key} className="ai-select-option">{DataSetSchemas?.[key]?.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="data-table-wrapper">
            <table className="data-table">
              <thead>
              <tr>
                {DataSetSchemas?.[selectedDataSetKey]?.columns?.map((column, index) => (
                  <th key={index}>{column?.title}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {formattedData?.map((row, index) => (
                <tr key={index}>
                  {DataSetSchemas?.[selectedDataSetKey]?.columns?.map((column, index) => (
                    <td key={index}>{row?.[column?.key]}</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <div className="dataset-header">
            <h4>2. Choose query type</h4>
            <div className="ai-select">
              <select id="typeSelect" value={queryComponent?.label}
                      onChange={(e) =>
                        setQueryComponent(componentOptions.find(item => item.label === e.target.value))
                      }
              >
                {componentOptions.map((option, index) => (
                  <option className="ai-select-option" key={index} value={option.label}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>

          <>
            <div className="dataset-header">
              <h4>3. What would you like to know?</h4>
            </div>
            <queryComponent.component formattedData={formattedData} columnDescriptions={columnDescriptions}/>
          </>

        </div>
      </section>
      <TechLogos/>
      <div className="watermark">
        <LogoSvg/>
        <div>
          .co.nz
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allAlbumsCsv {
    edges {
      node {
        Year
        Ranking
        Artist
        Album
        Worldwide_Sales
        Tracks
        Album_Length
        Genre
      }
     }
    }
    allSalesCsv {
      edges {
        node {
          ORDERNUMBER
          QUANTITYORDERED
          PRICEEACH
          ORDERLINENUMBER
          SALES
          ORDERDATE
          STATUS
          QTR_ID
          MONTH_ID
          YEAR_ID
          PRODUCTLINE
          MSRP
          PRODUCTCODE
          CUSTOMERNAME
          PHONE
          ADDRESSLINE1
          ADDRESSLINE2
          CITY
          STATE
          POSTALCODE
          COUNTRY
          TERRITORY
          CONTACTLASTNAME
          CONTACTFIRSTNAME
          DEALSIZE
        }
      }
    }
  }
`;