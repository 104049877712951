import {motion} from 'framer-motion';
import React, {useEffect, useReducer, useState} from 'react';
import BaseQueryBox from './BaseQueryBox';
import {dataStateReducer, useMessageHistory} from './utils';

const Insight = ({data, columns}) => {
  const [{
    errored,
    loading,
    code,
    lastQuery,
  }, dispatchInsightDataState] = useReducer(dataStateReducer, {errored: false, loading: false, code: '', lastQuery: ''});

  const [insightText, setInsightText] = useState('');

  const history = useMessageHistory();

  const onInsight = (value) => {
    if (value) {
      history.current.lastQuery = value;
      dispatchInsightDataState({type: 'loading', payload: value});
      fetch('https://pmkqpb5nq8.execute-api.ap-southeast-2.amazonaws.com/default/webfoxWebsiteChat2Vis/insight', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [...history.current?.messages, {role: 'user', content: value}],
          columns,
        }),
      }).then(response => response.json())
        .then((data) => {
          history.current.lastResponseProcessed = false;
          dispatchInsightDataState({type: 'success', payload: data?.content});
        })
        .catch(() => dispatchInsightDataState({type: 'error'}));
    }
  };

  useEffect(() => {
    if (code) {
      try {
        const generateInsight = eval(`(${code})`);
        if (typeof generateInsight !== 'function') {
          throw new Error('Invalid insight generator');
        }

        let insight = generateInsight(data);

        if (typeof insight === 'function') {
          insight = insight(data);
        }

        if (!history.current.lastResponseProcessed) {
          history.current.lastResponseProcessed = true;
          history.current.push({role: 'user', content: history.current?.lastQuery}, {role: 'assistant', content: code});
        }

        setInsightText(insight);
      } catch (error) {
        dispatchInsightDataState({type: 'error'});
      }
    }
  }, [data, code]);

  return (
    <BaseQueryBox
      loading={loading}
      errored={errored}
      onQuery={onInsight}
      lastQuery={lastQuery}
      onClear={() => {
        dispatchInsightDataState({type: 'clear'});
        setInsightText('');
        history.current.clear();
      }}
      loadingMessage="Generating answer"
      erroredMessage="An answer could not be generated. Please try again later."
      placeholderMessage="Enter a query about the sample data above…"
      inputPlaceholder="What do you want to know about the data above?"
    >
      {(!!code && !!insightText) &&
        <motion.pre
          key="insight"
          initial={{
            opacity: 0,
            y: 20,
          }}
          animate={{opacity: 1, y: 0}}
          exit={{
            opacity: 0,
            y: 20,
          }}
          className="ai-response-insight-container"
        >
          {insightText}
        </motion.pre>
      }
    </BaseQueryBox>
  );
};

export default Insight;