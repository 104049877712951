import {useRef} from 'react';

export const dataStateReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {...state, loading: true, errored: false, lastQuery: action?.payload};
    case 'success':
      return {...state, loading: false, errored: false, code: action?.payload};
    case 'error':
      return {...state, loading: false, errored: true};
    case 'clear':
      return {...state, loading: false, errored: false, code: '', lastQuery: ''};
    default:
      return state;
  }
};

export const useMessageHistory = () => {
  const history = useRef({
    messages: [],
    push: (...messages) => {
      history.current.messages.push(...messages);
    },
    clear: () => {
      history.current.messages = [];
    },
    lastQuery: '',
    lastResponseProcessed: false,
  })

  return history;
}